import { DrawerBody, DrawerFooter, VStack } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import FormBody from 'pages/ProductBundles/Form/Import/components/FormBody';
import {
  BODY_SCHEMA,
  DEFAULT_VALUES,
} from 'pages/ProductBundles/Form/Import/constants';
import { useFormWithSchema } from 'utils/formHooks';
import FormButtons from 'components/FormButtons';
import { useFormDrawer } from 'context/FormDrawerContext';
import notify from 'utils/notify';
import { API, APIRoutes } from 'api';

function ProductBundlesImport() {
  const queryClient = useQueryClient();
  const { closeFormDrawer } = useFormDrawer();

  const methods = useFormWithSchema(BODY_SCHEMA, {
    mode: 'onChange',
    defaultValues: DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  const { mutate: importProductBundles, isLoading } = useMutation({
    mutationKey: 'productBundlesMutation',
    mutationFn: (data: string) =>
      API.post(APIRoutes.productBundles.import, data, {
        headers: {
          'Content-Type': 'text/plain',
        },
      }),
    onSuccess: () => {
      closeFormDrawer();
      notify(
        'success',
        'Importowanie paczek produktów zakończyło się sukcesem',
      );
      queryClient.invalidateQueries('productBundles');
    },
    onError: () => {
      notify(
        'error',
        'Wystąpił problem podczas importowania paczek produktów. Upewnij się, że plik ma właściwą strukturę i nie zawiera duplikatów',
      );
    },
  });

  const onSubmit = handleSubmit(async ({ file }) => {
    const reader = new FileReader();
    reader.readAsText(file as Blob);
    reader.onload = () => {
      importProductBundles(reader.result as string);
    };
  });

  return (
    <>
      <DrawerBody>
        <FormProvider {...methods}>
          <VStack
            w="100%"
            h="100%"
            as="form"
            id="product-bundles-import-form"
            onSubmit={onSubmit}
            justify="space-between"
          >
            <FormBody isLoading={isLoading} />
          </VStack>
        </FormProvider>
      </DrawerBody>
      <DrawerFooter>
        <FormButtons
          onCancel={closeFormDrawer}
          isLoading={isLoading}
          isDisabled={!isValid}
          formId="product-bundles-import-form"
        />
      </DrawerFooter>
    </>
  );
}

export default ProductBundlesImport;
